import React, { useEffect, useState, useCallback } from 'react';
import { Empty, DatePicker } from 'antd';
import _ from 'underscore';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchMedia } from 'state/actions/media';
import { useParams, Link } from 'react-router-dom';
import { regexInput } from 'utils/regex';
import {
  fetchMediaCategories,
  setMediaCategory,
} from 'state/actions/mediaCategories';
import {
  fetchMediaAnalytic,
  storeMediaAnalytic,
  fetchTrafficAnalytic,
  fetchProfileAnalytic,
} from 'state/actions/mediaAnalytic';
import { ForwardIcon } from 'assets/icon';
import { EmptyIcon } from 'assets/icon/bigicon';
import Cards from 'design-components/Cards';
import { getAccess } from 'utils';
import CategoryDropdown from 'components/CategoryDropdown';
import { setTabMedia } from 'state/actions/breadcrumb';
import { fetchTags } from 'state/actions/tag';
import './MediaAnalytic.scss';

function MediaAnalytic() {
  const { siteId, siteName } = useParams();
  const [search, setSearch] = useState();
  const [error, setError] = useState(null);
  const [, setApprovalPermission] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [, setIsDeleteCatModalShow] = useState(false);
  const [tempSelectedCategory, setTempSelectedCategory] = useState(
    selectedCategory
  );
  const [, setIsModalCategoryShow] = useState(false);
  const [playlistOpen, setPlaylistOpen] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [, setSelectedMedia] = useState([]);
  const [, setDeletedCategory] = useState(null);
  const [, setAccess] = useState(null);

  const {
    mediaList,
    loading,
    updated,
    categoriesList,
    deleted,
    userData,
    role,
    selectedTab,
  } = useSelector(
    (state) => ({
      mediaList: state.media.list,
      categoriesList: state.mediaCategories.list,
      loading: state.media.loading,
      updated: state.media.updated,
      deleted: state.media.deleted,
      userData: state.auth.userData,
      role: state.auth.userData.role,
      selectedTab: state.breadcrumb.media,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const fetchMediaWithDates = useCallback(() => {
    const [start, end] = dateRange;
    dispatch(
      fetchMedia({
        siteId,
        categoryId: selectedCategory,
        start: start ? start.format('YYYY-MM-DD') : null,
        end: end ? end.format('YYYY-MM-DD') : null,
      })
    );
  }, [siteId, selectedCategory, dateRange]); // eslint-disable-line

  useEffect(() => {
    dispatch(
      fetchMedia({ siteId, categoryId: selectedCategory, type: selectedTab })
    );
    if (dateRange.length > 0) {
      fetchMediaWithDates();
    }
  }, [siteId, selectedCategory, selectedTab, dateRange]); // eslint-disable-line

  useEffect(() => {
    dispatch(fetchMediaAnalytic({ siteId, start: '', end: '' }));
    dispatch(storeMediaAnalytic({ siteId, start: '', end: '' }));
    dispatch(
      fetchTrafficAnalytic({
        siteId,
        startDate: '',
        endDate: '',
      })
    );
    dispatch(fetchProfileAnalytic({ siteId, startDate: '', endDate: '' }));
  }, [siteId, selectedTab, selectedCategory]); // eslint-disable-line

  useEffect(() => {
    if (userData && userData.roleAccess) {
      setAccess(getAccess(userData, 'media', siteId));
    }
  }, [userData, siteId]);

  useEffect(() => {
    setSelectedMedia([]);
  }, [selectedTab]);

  useEffect(() => {
    dispatch(fetchMediaCategories({ siteId }));
    dispatch(fetchTags({ siteId }));
    setSelectedCategory('all');
  }, [siteId]); // eslint-disable-line

  useEffect(() => {
    if (!loading && deleted) {
      fetchMediaWithDates();
    }
  }, [loading, deleted]); // eslint-disable-line

  useEffect(() => {
    if (userData.role === 'superadmin') {
      setApprovalPermission(true);
    } else if (
      userData.roleAccess.access.find((acc) => acc.siteId === siteId) &&
      userData.roleAccess.access.find((acc) => acc.siteId === siteId)
        .approval &&
      userData.roleAccess.access.find((acc) => acc.siteId === siteId).approval
        .update
    ) {
      setApprovalPermission(true);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!loading && updated) {
      fetchMediaWithDates();
    }
  }, [loading, updated]); // eslint-disable-line

  let data;

  if (search) {
    data = mediaList.filter((el) => {
      const clonedElem = { ...el };
      return clonedElem.name.toLowerCase().includes(search.toLowerCase());
    });
  } else if (dateRange.length > 0) {
    data = mediaList.filter((el) => {
      if (selectedTab === 'published') return el.status === 'published';
      if (selectedTab === 'unpublished') return el.status === 'unpublished';
      if (selectedTab === 'archived') return el.status === 'archived';
      return true; // Default to all media if no tab is selected
    });
  } else {
    data = mediaList; // Use the full mediaList if no dateRange is provided
  }

  const handleSearch = (e) => {
    setError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setError('Search cannot using special character');
    }

    setSearch(e.target.value);
  };

  const handleAddCategory = () => {
    dispatch(
      setMediaCategory({
        name: '',
        description: '',
      })
    );

    setIsModalCategoryShow(true);
  };

  const handleEditCategory = (id) => {
    const findCategory = categoriesList.find((cat) => cat._id === id);
    dispatch(setMediaCategory(findCategory));
    setIsModalCategoryShow(true);
  };

  const handleDeleteCategory = async (item) => {
    setDeletedCategory(item);
    setIsDeleteCatModalShow(true);
  };

  const handleSetPlaylist = () => {
    setSelectedCategory(tempSelectedCategory);
    setPlaylistOpen(false);
  };

  const getCategory = () => {
    const findCategory = categoriesList.find(
      (cat) => cat._id === selectedCategory
    );
    return findCategory?.name;
  };

  const onTabClicked = (value) => {
    dispatch(setTabMedia(value));
    fetchMediaWithDates();
  };

  const handleDateChange = useCallback((dates) => {
    if (!dates || dates.length === 0) {
      setDateRange([]);
      return;
    }

    if (dates.length === 2 && dates[0].isValid() && dates[1].isValid()) {
      setDateRange([dates[0].clone(), dates[1].clone()]);
    }
  }, []);

  return (
    <div className="media-library">
      <div className="media-header">
        <div className="media-header-left">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search" />
              <input
                placeholder="Search"
                className="search-input"
                value={search}
                onChange={handleSearch}
              />
            </div>
            {error && <div className="search-error">{error}</div>}
          </div>
          <div
            className="playlist-wrapper"
            onClick={() => setPlaylistOpen(!playlistOpen)}
          >
            <div className="playlist-selection">
              <ForwardIcon />
              <span>
                {selectedCategory === 'all'
                  ? 'All Playlist'
                  : getCategory(selectedCategory)}
              </span>
            </div>
            <i
              className={`feather-chevron-down ${
                playlistOpen ? 'playlist-open' : 'playlist-closed'
              }`}
            />

            <CategoryDropdown
              categories={categoriesList}
              setTempSelectedCategory={(id) => setTempSelectedCategory(id)}
              handleEditCategory={
                role === 'superadmin'
                  ? (cat) => handleEditCategory(cat._id)
                  : null
              }
              handleDeleteCategory={
                role === 'superadmin'
                  ? (cat) => handleDeleteCategory(cat)
                  : null
              }
              modalOpen={playlistOpen}
              handleSetPlaylist={handleSetPlaylist}
              tempSelectedCategory={tempSelectedCategory}
              handleNew={handleAddCategory}
              filter
            />
          </div>
          <DatePicker.RangePicker
            value={dateRange}
            onChange={handleDateChange}
            disabledDate={(current) => {
              if (!dateRange.length || !dateRange[0]) {
                return false;
              }
              return current && current.isBefore(dateRange[0], 'day');
            }}
          />
        </div>
      </div>
      <div className="media-content-wrapper">
        <div className="media-content">
          <div className="media-tab-wrapper">
            <div className="media-tab-left">
              <div
                onClick={() => onTabClicked('published')}
                className={`media-tab ${
                  selectedTab === 'published' ? 'active' : ''
                }`}
              >
                Published Items
              </div>
              <div
                onClick={() => onTabClicked('unpublished')}
                className={`media-tab ${
                  selectedTab === 'unpublished' ? 'active' : ''
                }`}
              >
                Unpublished Items
              </div>
              <div
                onClick={() => onTabClicked('archived')}
                className={`media-tab ${
                  selectedTab === 'archived' ? 'active' : ''
                }`}
              >
                Archived Items
              </div>
            </div>
          </div>
          <div className="media-list-content">
            {data.length > 0 ? (
              _.chunk(_.sortBy(data, 'sequence'), 3).map((chunk, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="media-list-chunk" key={index}>
                    {chunk.map((media) => {
                      return (
                        <Link
                          key={media._id}
                          to={`/${siteId}/information/${siteName}/mediaAnalytic/${media._id}`}
                          className="media-list"
                        >
                          <Cards
                            data={media}
                            type="media"
                            isShowMedia
                            isEdit={false}
                            isDelete={false}
                            toAnalytic
                          />
                        </Link>
                      );
                    })}
                    {chunk.length < 2 && <div className="media-list-empty" />}
                    {chunk.length < 3 && <div className="media-list-empty" />}
                  </div>
                );
              })
            ) : (
              <div className="media-empty">
                <Empty image={EmptyIcon} description="No Media" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaAnalytic;

import { createReducer } from 'redux-act';
import {
  MEDIAANALYTIC_STORE_DATA_INIT,
  MEDIAANALYTIC_STORE_DATA_SUCCESS,
  MEDIAANALYTIC_STORE_DATA_FAIL,
  MEDIAANALYTIC_FETCH_DATA_INIT,
  MEDIAANALYTIC_FETCH_DATA_SUCCESS,
  MEDIAANALYTIC_FETCH_DATA_FAIL,
  MEDIAANALYTIC_EXPORT_LOG_INIT,
  MEDIAANALYTIC_EXPORT_LOG_SUCCESS,
  MEDIAANALYTIC_EXPORT_LOG_FAIL,
  MEDIAANALYTIC_CLEAN_UP,
  TRAFFICANALYTIC_FETCH_DATA_INIT,
  TRAFFICANALYTIC_FETCH_DATA_SUCCESS,
  TRAFFICANALYTIC_FETCH_DATA_FAIL,
  PROFILEANALYTIC_FETCH_DATA_INIT,
  PROFILEANALYTIC_FETCH_DATA_SUCCESS,
  PROFILEANALYTIC_FETCH_DATA_FAIL,
  TRAFFICANALYTIC_FETCH_DETAILS_INIT,
  TRAFFICANALYTIC_FETCH_DETAILS_SUCCESS,
  TRAFFICANALYTIC_FETCH_DETAILS_FAIL,
  PROFILEANALYTIC_FETCH_DETAILS_INIT,
  PROFILEANALYTIC_FETCH_DETAILS_SUCCESS,
  PROFILEANALYTIC_FETCH_DETAILS_FAIL,
} from 'state/actions/mediaAnalytic';

const initialState = {
  list: [], // List of media analytics
  trafficData: null,
  profileData: null,
  trafficDetails: null,
  profileDetails: null,
  loading: false,
  error: null,
  deleted: false,
  downloading: false,
};

export const mediaAnalyticsReducer = createReducer(
  {
    [MEDIAANALYTIC_STORE_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [MEDIAANALYTIC_STORE_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      loading: false,
      error: null,
    }),
    [MEDIAANALYTIC_STORE_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MEDIAANALYTIC_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [MEDIAANALYTIC_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      loading: false,
      error: null,
    }),
    [MEDIAANALYTIC_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TRAFFICANALYTIC_FETCH_DATA_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TRAFFICANALYTIC_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      trafficData: payload.trafficData || null,
      loading: false,
      error: null,
    }),
    [TRAFFICANALYTIC_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PROFILEANALYTIC_FETCH_DATA_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PROFILEANALYTIC_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      profileData: payload.profileData || null,
      loading: false,
      error: null,
    }),
    [PROFILEANALYTIC_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TRAFFICANALYTIC_FETCH_DETAILS_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TRAFFICANALYTIC_FETCH_DETAILS_SUCCESS]: (state, payload) => {
      return {
        ...state,
        trafficDetails: payload.trafficDetails || null,
        loading: false,
        error: null,
      };
    },
    [TRAFFICANALYTIC_FETCH_DETAILS_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PROFILEANALYTIC_FETCH_DETAILS_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PROFILEANALYTIC_FETCH_DETAILS_SUCCESS]: (state, payload) => ({
      ...state,
      profileDetails: payload.profileDetails || null,
      loading: false,
      error: null,
    }),
    [PROFILEANALYTIC_FETCH_DETAILS_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MEDIAANALYTIC_EXPORT_LOG_INIT]: (state) => ({
      ...state,
      downloading: true,
    }),
    [MEDIAANALYTIC_EXPORT_LOG_SUCCESS]: (state) => ({
      ...state,
      downloading: false,
    }),
    [MEDIAANALYTIC_EXPORT_LOG_FAIL]: (state) => ({
      ...state,
      downloading: false,
    }),
    [MEDIAANALYTIC_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
    }),
  },
  initialState
);

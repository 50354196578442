import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { errorMsg } from 'utils';
import { url } from 'utils/url';
import moment from 'moment';
import fileDownload from 'js-file-download';

export const MEDIAANALYTIC_STORE_DATA_INIT = createAction(
  'MEDIAANALYTIC_STORE_DATA_INIT'
);
export const MEDIAANALYTIC_STORE_DATA_SUCCESS = createAction(
  'MEDIAANALYTIC_STORE_DATA_SUCCESS'
);
export const MEDIAANALYTIC_STORE_DATA_FAIL = createAction(
  'MEDIAANALYTIC_STORE_DATA_FAIL'
);
export const MEDIAANALYTIC_FETCH_DATA_INIT = createAction(
  'MEDIAANALYTIC_FETCH_DATA_INIT'
);
export const MEDIAANALYTIC_FETCH_DATA_SUCCESS = createAction(
  'MEDIAANALYTIC_FETCH_DATA_SUCCESS'
);
export const MEDIAANALYTIC_FETCH_DATA_FAIL = createAction(
  'MEDIAANALYTIC_FETCH_DATA_FAIL'
);

export const TRAFFICANALYTIC_FETCH_DATA_INIT = createAction(
  'TRAFFICANALYTIC_FETCH_DATA_INIT'
);
export const TRAFFICANALYTIC_FETCH_DATA_SUCCESS = createAction(
  'TRAFFICANALYTIC_FETCH_DATA_SUCCESS'
);
export const TRAFFICANALYTIC_FETCH_DATA_FAIL = createAction(
  'TRAFFICANALYTIC_FETCH_DATA_FAIL'
);

export const TRAFFICANALYTIC_FETCH_DETAILS_INIT = createAction(
  'TRAFFICANALYTIC_FETCH_DETAILS_INIT'
);
export const TRAFFICANALYTIC_FETCH_DETAILS_SUCCESS = createAction(
  'TRAFFICANALYTIC_FETCH_DETAILS_SUCCESS'
);
export const TRAFFICANALYTIC_FETCH_DETAILS_FAIL = createAction(
  'TRAFFICANALYTIC_FETCH_DETAILS_FAIL'
);

export const PROFILEANALYTIC_FETCH_DETAILS_INIT = createAction(
  'PROFILEANALYTIC_FETCH_DETAILS_INIT'
);
export const PROFILEANALYTIC_FETCH_DETAILS_SUCCESS = createAction(
  'PROFILEANALYTIC_FETCH_DETAILS_SUCCESS'
);
export const PROFILEANALYTIC_FETCH_DETAILS_FAIL = createAction(
  'PROFILEANALYTIC_FETCH_DETAILS_FAIL'
);

export const MEDIAANALYTIC_EXPORT_LOG_INIT = createAction(
  'MEDIAANALYTIC_EXPORT_LOG_INIT'
);
export const MEDIAANALYTIC_EXPORT_LOG_SUCCESS = createAction(
  'MEDIAANALYTIC_EXPORT_LOG_SUCCESS'
);
export const MEDIAANALYTIC_EXPORT_LOG_FAIL = createAction(
  'MEDIAANALYTIC_EXPORT_POI_FAIL'
);

export const MEDIAANALYTIC_CLEAN_UP = createAction('MEDIAANALYTIC_CLEAN_UP');

export const PROFILEANALYTIC_FETCH_DATA_INIT = createAction(
  'PROFILEANALYTIC_FETCH_DATA_INIT'
);
export const PROFILEANALYTIC_FETCH_DATA_SUCCESS = createAction(
  'PROFILEANALYTIC_FETCH_DATA_SUCCESS'
);
export const PROFILEANALYTIC_FETCH_DATA_FAIL = createAction(
  'PROFILEANALYTIC_FETCH_DATA_FAIL'
);

export const storeMediaAnalytic = ({ siteId, start, end }) => {
  return async (dispatch) => {
    dispatch(MEDIAANALYTIC_STORE_DATA_INIT());
    let response;
    try {
      response = await axios.post(
        `${url}/mediaAnalytic/store`,
        { siteId, start, end },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );

    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(MEDIAANALYTIC_STORE_DATA_FAIL({ error }));
    }

    return dispatch(
      MEDIAANALYTIC_STORE_DATA_SUCCESS({
        message: response.data.message,
      })
    );
  };
};

export const fetchProfileAnalytic = ({ siteId, startDate, endDate }) => {
  return async (dispatch) => {
    dispatch(PROFILEANALYTIC_FETCH_DATA_INIT());
    try {
      const response = await axios.post(
        `${url}/mediaAnalytic/profileAnalytic`,
        {
          siteId,
          startDate,
          endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );

      dispatch(
        PROFILEANALYTIC_FETCH_DATA_SUCCESS({
          profileData: response.data.data,
        })
      );
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      dispatch(PROFILEANALYTIC_FETCH_DATA_FAIL({ error }));
    }
  };
};

export const fetchMediaAnalytic = ({ siteId, start, end }) => {
  return async (dispatch) => {
    dispatch(MEDIAANALYTIC_FETCH_DATA_INIT());
    let mediaanalytics;
    try {
      mediaanalytics = await axios.post(
        `${url}/mediaAnalytic/get`,
        {
          siteId,
          start,
          end,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(MEDIAANALYTIC_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      MEDIAANALYTIC_FETCH_DATA_SUCCESS({
        list: mediaanalytics.data.data,
      })
    );
  };
};

export const fetchTrafficAnalytic = ({ siteId, startDate, endDate }) => {
  return async (dispatch) => {
    dispatch(TRAFFICANALYTIC_FETCH_DATA_INIT());

    try {
      const trafficAnalytics = await axios.post(
        `${url}/mediaAnalytic/trafficAnalytic/`,
        {
          siteId,
          startDate,
          endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );

      // Check if trafficAnalytics and trafficAnalytics.data are defined
      if (trafficAnalytics && trafficAnalytics.data) {
        dispatch(
          TRAFFICANALYTIC_FETCH_DATA_SUCCESS({
            trafficData: trafficAnalytics.data.data,
          })
        );
      } else {
        throw new Error('Invalid response structure from the API');
      }
    } catch (error) {
      // Handle 404 or other errors
      if (error.response && error.response.status === 404) {
        toastr.error('', 'The requested resource was not found (404).');
      } else {
        toastr.error('', `${errorMsg(dispatch, error)}`);
      }

      dispatch(
        TRAFFICANALYTIC_FETCH_DATA_FAIL({
          error: error.message || 'Failed to fetch traffic analytics',
        })
      );
    }
  };
};

export const downloadMediaAnalytic = () => {
  return async (dispatch) => {
    dispatch(MEDIAANALYTIC_EXPORT_LOG_INIT());
    axios
      .get(`${url}/mediaanalytic/export`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `mediaanalytics_export_${moment(new Date()).format('YYYY-MM-DD')}.csv`
        );
        return dispatch(MEDIAANALYTIC_EXPORT_LOG_SUCCESS());
      })
      .catch((error) => {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(MEDIAANALYTIC_EXPORT_LOG_FAIL({ error }));
      });
  };
};

export const fetchTrafficAnalyticDetails = ({
  mediaId,
  startDate,
  endDate,
}) => {
  return async (dispatch) => {
    dispatch(TRAFFICANALYTIC_FETCH_DETAILS_INIT());
    try {
      const response = await axios.post(
        `${url}/mediaAnalytic/trafficAnalytic/details`,
        {
          mediaId,
          startDate,
          endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );

      dispatch(
        TRAFFICANALYTIC_FETCH_DETAILS_SUCCESS({
          trafficDetails: response.data.data,
        })
      );
    } catch (error) {
      // toastr.error('', `${errorMsg(dispatch, error)}`);
      // dispatch(TRAFFICANALYTIC_FETCH_DETAILS_FAIL({ error }));
    }
  };
};

export const fetchProfileAnalyticDetails = ({
  mediaId,
  startDate,
  endDate,
}) => {
  return async (dispatch) => {
    dispatch(PROFILEANALYTIC_FETCH_DETAILS_INIT());
    try {
      const response = await axios.post(
        `${url}/mediaAnalytic/profileAnalytic/details`,
        {
          mediaId,
          startDate,
          endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );

      dispatch(
        PROFILEANALYTIC_FETCH_DETAILS_SUCCESS({
          profileDetails: response.data.data,
        })
      );
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      dispatch(PROFILEANALYTIC_FETCH_DETAILS_FAIL({ error }));
    }
  };
};

export const mediaanalyticsCleanUp = () => (dispatch) =>
  dispatch(MEDIAANALYTIC_CLEAN_UP());

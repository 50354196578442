import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getAccess } from 'utils';
import DOMPurify from 'dompurify';
import moment from 'moment';
import CreatedInfo from 'elements/created-info';
import TooltipMenu from 'elements/TooltipMenu';
import {
  EditIcon,
  ForwardIcon,
  ImportIcon,
  MaximizeIcon,
  TrashIcon,
  CalendarTickIcon,
  LayerIcon,
  LocationIcon,
  DurationIcon,
} from 'assets/icon';
import AnalyticIcon from 'assets/icon/dynamic/AnalyticIcon';
import CardInfo from 'elements/card-info';
import CardTitle from 'elements/card-title';
import { imgUrl } from 'utils/url';
import './style.css';
import defaultLogo from '../../assets/default-logo.png';

const Cards = ({
  data,
  type,
  isShowMedia,
  editAction,
  deleteAction,
  previewAction,
  downloadAction,
  cardClickAction,
  isPreview = false,
  isDownload = false,
  isClickable = false,
  isEdit = true,
  isDelete = true,
  toAnalytic = false,
}) => {
  const { siteId } = useParams();
  const [access, setAccess] = useState(null);

  const { userData, role } = useSelector(
    (state) => ({
      userData: state.auth.userData,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (userData && userData.roleAccess) {
      setAccess(getAccess(userData, type, siteId));
    }
  }, [userData, type, siteId]);

  const mediaDetails = (
    <div className="card-list-details">
      <CardTitle title={data.name} />
      <CardInfo
        icon={<ForwardIcon style={{ width: 16, height: 16 }} />}
        title={
          data.categoryName &&
          (data.categoryName.length > 20
            ? `${data.categoryName.substr(0, 20)}...`
            : data.categoryName)
        }
      />
      <CardInfo
        icon={<CalendarTickIcon style={{ width: 16, height: 16 }} />}
        title={(() => {
          if (data.type === 'date') {
            return `${moment(data.start).format('MMM, D YYYY')} - ${moment(data.end).format('MMM, D YYYY')}`;
          }
          if (data.type === 'day' && data.days) {
            return `${data.days[0].day} - ${data.days[data.days.length - 1].day}`;
          }
          if (data.start && data.end) {
            return `${moment(data.start).format('MMM, D YYYY')} - ${moment(data.end).format('MMM, D YYYY')}`;
          }
          return 'All Day';
        })()}
      />
      {data.fileType && (
        <CardInfo
          icon={<DurationIcon style={{ width: 16, height: 16 }} />}
          title={
            data.fileType.split('/')[0] === 'video'
              ? 'Video Duration'
              : data.duration && `${data.duration} seconds`
          }
        />
      )}
    </div>
  );

  const tenantDetails = (
    <div className="card-list-details">
      <CardTitle title={data.name} />
      <CardInfo
        icon={<LayerIcon style={{ width: 16, height: 16 }} />}
        title={
          data.floorName && data.floorName > 20
            ? `${data.floorName.substr(0, 20)}...`
            : data.floorName
        }
      />
      <CardInfo
        icon={<LocationIcon style={{ width: 16, height: 16 }} />}
        title={
          data.location && data.location.length > 20
            ? `${data.location.substr(0, 20)}...`
            : data.location
        }
      />
    </div>
  );

  const HtmlView = ({ htmlString }) => {
    const sanitizedHtmlString = DOMPurify.sanitize(htmlString);
    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: sanitizedHtmlString }}
        style={{ height: '50px', overflow: 'hidden' }}
      />
    );
  };

  const descDetails = (
    <div className="card-list-details">
      <CardTitle title={data.name} />
      {data.enableHtmlView ? (
        <HtmlView htmlString={data.description} />
      ) : (
        <p className="card-list-subtitle">{data.description}</p>
      )}
    </div>
  );

  const showByType = () => {
    switch (type) {
      case 'media':
        return mediaDetails;
      case 'tenant':
        return tenantDetails;
      default:
        return descDetails;
    }
  };

  const renderMedia = () => {
    if (data.logo && data.fileType) {
      const fileType = data.fileType.split('/')[0];
      if (fileType === 'image') {
        return (
          <img
            src={`${imgUrl}/media/${data.logo}`}
            alt="default"
            className="card-list-img"
            draggable={false}
          />
        );
      }
      if (fileType === 'video') {
        return (
          <video
            id="my-video"
            className="video-js card-list-video"
            data-setup="{}"
            preload="metadata"
          >
            <source src={`${imgUrl}/media/${data.logo}`} type="video/mp4" />
            <track kind="captions" />
          </video>
        );
      }
    }
    return null;
  };
  
  const media = (
    <>
      {renderMedia()}
    </>
  );

  const pois = (
    <img
      src={data.logo ? `${imgUrl}/poi/${data.logo}` : defaultLogo}
      alt="default"
      className="card-list-img"
      draggable={false}
    />
  );

  return (
    <>
      <div
        className="card-list-container"
        onClick={isClickable ? cardClickAction : () => {}}
      >
        {type === 'tenant' && isShowMedia ? pois : null}
        {type === 'media' && isShowMedia ? media : null}
        {showByType()}
      </div>
      <div className="card-list-footer">
        <div className="card-list-footer-left">
          <CreatedInfo user={data.modifiedBy} />
        </div>
        <div
          className="card-list-footer-right"
          onClick={(e) => e.stopPropagation()}
        >
          <ul>
            {isEdit && (role !== 'superadmin' ? (
              access && access.update && (
                <li>
                  <TooltipMenu
                    title="Edit"
                    clickAction={editAction}
                    icon={<EditIcon style={{ width: 18, height: 18 }} />}
                  />
                </li>
              )
            ) : (
              <li>
                <TooltipMenu
                  title="Edit"
                  clickAction={editAction}
                  icon={<EditIcon style={{ width: 18, height: 18 }} />}
                />
              </li>
            ))}
            {isPreview && (
              <li>
                <TooltipMenu
                  title="Preview"
                  clickAction={previewAction}
                  icon={<MaximizeIcon style={{ width: 18, height: 18 }} />}
                />
              </li>
            )}
            {type !== 'screen' && isDownload && (
              <li>
                <TooltipMenu
                  title="Download"
                  clickAction={downloadAction}
                  icon={<ImportIcon style={{ width: 18, height: 18 }} />}
                />
              </li>
            )}
            {isDelete && (role !== 'superadmin' ? (
              access && access.delete && (
                <li>
                  <TooltipMenu
                    title="Delete"
                    clickAction={deleteAction}
                    icon={<TrashIcon style={{ width: 18, height: 18 }} />}
                  />
                </li>
              )
            ) : (
              <li>
                <TooltipMenu
                  title="Delete"
                  clickAction={deleteAction}
                  icon={<TrashIcon style={{ width: 18, height: 18 }} />}
                />
              </li>
            ))}
            {toAnalytic && (role !== 'superadmin' ? (
              access && access.delete && (
                <li>
                  <TooltipMenu
                    title="Delete"
                    clickAction={deleteAction}
                    icon={<AnalyticIcon style={{ width: 18, height: 18 }} />}
                  />
                </li>
              )
            ) : (
              <li>
                <TooltipMenu
                  title="Delete"
                  clickAction={deleteAction}
                  icon={<AnalyticIcon style={{ width: 18, height: 18 }} />}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Cards;
import React from 'react';
const AnalyticIcon = ({ color = '#000', size = 24 }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66797 18.3333H18.3346"
        stroke="#0851B5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.125 3.33341V18.3334H11.875V3.33341C11.875 2.41675 11.5 1.66675 10.375 1.66675H9.625C8.5 1.66675 8.125 2.41675 8.125 3.33341Z"
        stroke="#0851B5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 8.33341V18.3334H5.83333V8.33341C5.83333 7.41675 5.5 6.66675 4.5 6.66675H3.83333C2.83333 6.66675 2.5 7.41675 2.5 8.33341Z"
        stroke="#0851B5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.168 12.4999V18.3333H17.5013V12.4999C17.5013 11.5833 17.168 10.8333 16.168 10.8333H15.5013C14.5013 10.8333 14.168 11.5833 14.168 12.4999Z"
        stroke="#0851B5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AnalyticIcon;
